import React from "react";
import "./header.scss";
import { useSelector } from "react-redux";

export const Header = () => {
  const userName = useSelector((state) => state.user.userName);

  console.log("userName", userName);
  return (
    <div>
      <img className="image" src="logo.png" alt="Utebio 2020"></img>
      <h5 className="ml-4 mt-2">Välkommen {userName.name}!</h5>
    </div>
  );
};

export default Header;
