import React from "react";
import { Col } from "reactstrap";

export const MovieListItem = (movie) => {
  console.log("movie", movie);
  const { Title, Poster, Year, imdbID } = movie.movie;
  return (
    <>
      <Col>
        <a
          href={`https://www.imdb.com/title/${imdbID}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img height="100px" src={Poster} alt="Poster" />
        </a>
      </Col>
      <Col>
        <a
          href={`https://www.imdb.com/title/${imdbID}`}
          target="blank"
          rel="noopener noreferrer"
        >
          <p>{Title}</p>
          <p>{Year}</p>
        </a>
      </Col>
    </>
  );
};
export default MovieListItem;
