import React, { useState } from "react";
import axios from "axios";
import { OMDB_API_KEY } from "../../utils/constants";
import { FormGroup, Form, Input, Row, Col } from "reactstrap";
import MovieListItem from "./movie-list-list.component";

export const Start = () => {
  const [title, setTitle] = useState("");

  // send a POST request
  const getMovie = (name) => {
    const url = `http://www.omdbapi.com/?s=${name}&apikey=${[OMDB_API_KEY]}`;
    axios
      .get(url, {
        s: { name },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          if (response.data.Response === "False") {
            return "";
          }
          console.log(response.data.Search);
          setTitle(
            response.data.Search.filter((item) => item).map((movie) => (
              <Row>
                {console.log("movie1", movie)}
                <MovieListItem key={movie.imdbID} movie={movie} />
              </Row>
            ))
          );
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <div>
      <FormGroup>
        <Form>
          <Input
            type="search"
            id="search3"
            placeholder="star wars"
            onChange={(name) => getMovie(name.target.value)}
          />
        </Form>
      </FormGroup>
      <Col>{title}</Col>
    </div>
  );
};

export default Start;
