import React, { useState } from "react";
import { Label, Input, Form, FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

export const SignIn = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const addUser = (name) => {
    dispatch({
      type: "ADD_USER",
      payload: {
        name: { name },
      },
    });
  };

  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          type="text"
          placeholder="Fyll i ditt namn"
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          value={name}
          type="submit"
          onClick={(e) => addUser(e.target.value)}
        >
          Fortsätt
        </Button>
      </Form>
    </div>
  );
};

export default SignIn;
