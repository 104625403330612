import React, { useState } from "react";
import { Header } from "./components/header/header.component";
import { Card, CardBody } from "reactstrap";
import { SignIn } from "./components/sign-in/sign-in.component";
import { Start } from "./components/start/start.component";
import { useSelector } from "react-redux";

function App() {
  const isSignedIn = useSelector((state) => state.user.signedIn);
  console.log("signedIn", isSignedIn);
  return (
    <div>
      <Card>
        <Header />
        <CardBody>{!isSignedIn && <SignIn />}</CardBody>
        <CardBody>{isSignedIn && <Start />}</CardBody>
      </Card>
    </div>
  );
}

export default App;
