const initialState = {
  userName: "",
  signedIn: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER":
      console.log("hej");
      console.log("action", action.payload.name);
      return {
        ...state,
        userName: action.payload.name,
        signedIn: true,
      };
    default:
      return state;
  }
};
